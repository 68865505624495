import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    redirect: '/home',
  },

  // {
  //   path: '/documents',
  //   name: 'documents',

  //   component: function () {
  //     return import('../views/DocumentsView.vue')
  //   },
    
  // },
  {
    path: '/admin',
    name: 'admin',

    component: function () {
      return import('../views/AdminView.vue')
    },
    
  },
  {
    path: '/admin',
    name: 'admin',

    component: function () {
      return import('../views/AdminView.vue')
    },
    
  },
  

  {
    path: '/admin/login',
    name: 'admin-login',

    component: function () {
      return import('../views/AdminLoginView.vue')
    },
    
  },

  // {
  //   path: '/gallery',
  //   name: 'gallery',

  //   component: function () {
  //     return import('../views/GalleryView.vue')
  //   },
    
  // },

  {
    path: '/apply',
    name: 'apply',

    component: function () {
      return import('../views/Application.vue')
    },
    
  },

{
  path: '/:pageName',
  name: 'dynamicPage',
  component: () => import('../views/DynamicPageView.vue'),
  props: true,
  key: (route) => route.params.pageName || 'default',
}
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
