<template>
  <nav>
    <div class="haut" style="height:70px">
      <a href="https://tunneltoads.com" id="logo"><img src="https://i.imgur.com/oMzGNvA.png"
          style="width:80px; padding-left: 10px; padding-top: 15px;" alt="logo"></a> <!-- 34.5px left -->
          
      <div v-if="isMobile" class="mobile-navbar">
         <button class="menu-icon" @click="showMenu" >&#9776;</button>
          <div
          class="nav-content-m"
          :class="this.showMobileMenu ? 'open-menu-m' : 'closed-menu-m'"
          :style="{ pointerEvents: this.showMobileMenu ? '' : 'none' }"
          >
          <div class="encap m">
          <ul class="nav-items-m">
<ul id="menu-deroulant">
          <li ><router-link class="nav-link" to="/" @click="showMenu">Home</router-link></li>
          <li><router-link class="nav-link" to="/gallery" @click="showMenu">Gallery</router-link></li>
          <!-- <li><a class="nav-link" href="https://forum.tunneltoads.com/" @click="showMenu">Forums</a></li> -->
          <li> <router-link class="nav-link" to="/about" @click="showMenu">About</router-link></li>
          <li><router-link class="nav-link" to="/documents" @click="showMenu">Documents</router-link></li>
          <li><router-link class="nav-link" to="/contact" @click="showMenu">Contact</router-link></li>
          </ul>
          </ul>
          </div>
      </div>
      </div>

      <span class="useroptions" ref="desktopMenu" :style="{ display: isMobile ? 'none' : '' }">
       
        
        <ul id="menu-deroulant" class="bottom">

          <li><router-link class="nav-link" to="/">Home</router-link></li>
          <li><router-link class="nav-link" to="/gallery">Gallery</router-link></li>
          <!-- <li><a class="nav-link" href="https://forum.tunneltoads.com/">Forums</a></li> -->
          <li> <router-link class="nav-link" to="/about">About</router-link></li>
          <li><router-link class="nav-link" to="/documents">Documents</router-link></li>
          <li><router-link class="nav-link" to="/contact">Contact</router-link></li>
        </ul>
      </span>



      
    </div>
  </nav>
  <div :style="{'padding-top': '80px'}"></div>
  <router-view />
  <div style="padding: 30px;"></div> <!-- A bit of space at the bottom -->
</template>
<script>
export default {
  data() {
    return {
      desktopMenuPosition: 0,
      isMobile: false,
      showMobileMenu: false,
      window: {
            width: 0,
            height: 0
        },
    };
  },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            this.setMobile();
        },
        setMobile() {
          this.isMobile = (this.window.width <= 644);
        },
        showMenu() {
          this.showMobileMenu = !this.showMobileMenu;
        },
    },

};</script>
<style>

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.5s;
}

.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
  transform: translateX(-200px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#og a {
  padding: 5px;
  font-size: 10px;
  text-decoration: none;
  text-align: center;
  transition: color 0.3s ease, background-color 0.3s ease;
}

#og img {
    width: 50%;
    mix-blend-mode: screen;
    pointer-events: none;
    user-select: none;
}

table { 
  border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

        th, td {
            border: 1px solid #9ACD32;
            text-align: left;
            padding: 8px;
        }

        th {
            background-color: #333333;
        }

        tr:nth-child(even) {
            background-color: #2c2c2c;
        }

        #og a {
            color: red; /* Red links */
            text-decoration: none;
        }
        #og a:hover { 
            background-color: red; 
            color: black; 
        }

.encap {
    position: fixed;
    width: 100%;
    padding-bottom: 40px;
    background-color: rgb(36, 36, 36);
}


.menu-icon {
  border: hidden;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  font-size: 30px;
  float: right;
  vertical-align: middle;
  position: relative;
  right: 15px;
  letter-spacing: 0.4px;
  position: absolute;
  padding: 0;
  margin: 0;
  top: 15px;
}

.nav-menu-m {
padding-top: 10px;
position: absolute;
width: 100%;
}.open-menu-m {
opacity: 1;
height: 150px;
}.closed-menu-m {
opacity: 0;
height: 0;
padding: 0;
}.nav-content-m {
flex-direction: column;
z-index: 100;
position: relative;
transition: all 0.2s ease-out;
}.nav-items-m {
flex-direction: column;
}#m i {
display: block;
text-align: center;
padding: 0 10px 10px 0;
}

#App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #161616;
  color: #a3a3a3;
  text-align: center;
  line-height: 1.4;
  margin: 0;
  overflow-y: scroll;
  text-decoration: none;
  font-family: Open Sans;
  font-size: 13px;
}


#menu-deroulant li {
  display: inline-block;
}

#menu-deroulant {
  letter-spacing: 0.4px;
}

#menu-deroulant {
  text-align: center;
}

#menu-deroulant a {
  text-decoration: none;
  display: block;
  color: #000;
}

#menu-deroulant li a {
  color: rgb(230, 230, 230);
  padding: 25px 14px;
  transition: background-color 200ms, color 200ms;
  font-size: 14px;
}


.haut {
  text-align: left;
}

.haut {
  background: rgb(36, 36, 36);
  width: 100%;
  float: right;
  box-shadow: 0px 1px 1px 1px rgb(0, 0, 0, 0.3);
  position: fixed;
  height: 70px;
  z-index: 1000;
  text-align: left;
}

#menu-deroulant {
  float: right;
  vertical-align: middle;
  position: relative;
  right: 35px;
  letter-spacing: 0.4px;
}

#menu-deroulant,
#menu-deroulant ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#menu-deroulant {
  text-align: center;
}

#menu-deroulant,
#menu-deroulant ul {
  list-style: none;
  line-height: 1.4;
  font-family: Open Sans;
}

nav {
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
}

#menu-deroulant li:hover {
	background: rgb(18,18,18);
	color: rgb(123,123,123);
}

.bottom li:hover {
  	border-bottom: 2px solid #9ACD32;
}



#menu-deroulant li:hover ul li:hover {
	color: #9ACD32;
	border:none;
}

#menu-deroulant li:hover ul li:hover a {
	background: rgb(18,18,18);
}




/* li:hover:before {
 TODO
} */
</style>
